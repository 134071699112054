@import "./variables.scss";
@import "./react-toggle.scss";
@import "./charts.scss";
@import "./footer.scss";

@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

$primary: $primaryColor;
$family-sans-serif: 'Nunito', sans-serif;
@import "~bulma/bulma.sass";
@import '~@creativebulma/bulma-collapsible';
@import '~bulma-timeline/dist/css/bulma-timeline.sass';

@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

.logo-background {
    background-color: $logoBackground;
}

.pointer-cursor {
    cursor: pointer;
}

.selectorBackground {
    background-color: $selectorBackground;
}

.is-slightly-smaller-than-size-6 {
    font-size: 13px;
    line-height: 17px;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.opacity-7 {
    opacity: .7;

    &:hover {
        opacity: .9;  
    }
}

.app-mt-6 {
    margin-top: 2.4rem;
}


.fullWidth {
    width: 100%;
}

.thinHr {
    margin: 4px 0px 8px;
}
// .svgContainer {
//     display: inline-block;
//     position: relative;
//     width: 100%;
//     height: auto;
//     vertical-align: top;
//     overflow: hidden;
// }

// .svgContent {
//     display: inline-block;
//     position: absolute;
//     top: 48;
//     left: 0;
// }


.column-border {
    border: 1px solid $logoBackground;
}

.column-border-right {
    border-right: 1px solid $logoBackground;
}

.column-border-left {
    border-left: 1px solid $logoBackground;
}

.dotted-border-right {
    border-right: 1px dashed #ccc;
}

.absolute-bottom-right {
    position: absolute;
    bottom: 0px;
    right: 4px;
}

.height-100 {
    height: 92vh;
    max-height: 100vh;
    overflow-y: auto;
}

.active-border-bottom {
    border-bottom: 2px solid $primaryColor;
}

.active-border-bottom-thin {
    border-bottom: 1px solid hsl(0, 0%, 71%);
}

.no-border-radius {
    border-radius: 0px;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 0;
    transition: height 0.15s ease-out;

    :horizontal {
        height: 6px;
    }
}


.barClickArea {
    margin-left: -16px;
}

.table-container, .barClickArea {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &:hover::-webkit-scrollbar {
        width: 4px;
        height: 7px;
        transition: width 0.25s ease-in;
        transition: height 0.25s ease-in;
    }
}

.questionEditorArea {
    &::-webkit-scrollbar {
        height: 0;
    } 

    &:hover::-webkit-scrollbar {
        height: 0px;
    }
}


*::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    opacity: 0.1;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
*:hover::-webkit-scrollbar {
    height: 0.6em;
    transition: height 0.25s ease-in;
}
*:hover::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
*::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.55);
}


.big-modal {
    width: 780px !important;
}

.medium-modal {
    width: 720px !important;
    min-height: 480px;
}

.is-underlined {
    text-decoration: underline;
}


.stickyHeader {
    top: 0;
    z-index: 2;
    position: sticky;
}


.border-radius {
    border: 2px;
}
/**************************************************************/

// .d3-tip {
//     line-height: 1;
//     font-weight: bold;
//     padding: 12px;
//     background: rgba(0, 0, 0, 0.8);
//     color: #fff;
//     border-radius: 2px;
//     pointer-events: none;
// }

.chartToolTip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    position: absolute;
    pointer-events: none;
}

.chartToolTip.e:after {
    content: "\25C0";
    margin: -4px 0 0 0;
    top: 50%;
    left: -8px;
}

.chartToolTip {
    background-color: #efefef;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 4px;
    min-width: 200px;
    max-width: 240px;
    height: auto;
    font-size: 12px;
  
    position: absolute;
    right: 0px;
    top: -8px;

    transition: all 0.1s ease-out;

    font-family: "Roboto", sans-serif;
    box-sizing: content-box;
}
  
.toolTipList {
    text-align: left;
    box-sizing: content-box;
    // display: block;

    ul {
        list-style: none;
        padding-left: 0px;

        li {
            padding: 4px 4px 0 4px;
          
            display: inline-block;  
            box-sizing: content-box;
            line-height: inherit;
        
            &:first-child {
                display: block;
                width: 100%;
            }
        }
    }
  }
  

.thinInput {
    border: 0px;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px 6px;
    font-size: 14px;
    max-width: 100%;

    &:focus, &:active {
        outline: none;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #999;
    }
}


.thinSelector > div:first-of-type {
    border: 0px;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
    font-size: 14px;
}

.thinSelector {
    .select-prefix__menu {
        z-index: 1000 !important;
    }
}


.viewerTitleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.is-active {
    &.button-list {
        z-index: 0 !important;
    }
}

/**************************************************/

// .markdown {
//     h1 {
//         font-size: 3rem;
//     }

//     h2 {
//         font-size: 2.4rem;
//     }

//     h3 {
//         font-size: 1.8rem;
//     }

//     h4 {
//         font-size: 1.5rem;
//     }
// }

h2, h3 {
    scroll-margin-top: 24px;
}


/***************** Overriding Card ***********************/

.cardContent {
    flex: 1 1 100%;
    position: relative;
}

.cardFooter {
    flex: 1 1 auto;
}


.cardHeader {
    box-shadow: none;
    border-bottom: 1px solid #eee;
}

.cardCustom {
    display: flex !important;
    flex-direction: column;
    
    // &:nth-child(3n+0) {
    //     margin: 0 4px 0 0px;
    // }

    // &:nth-child(3n+1) {
    //     margin: 0 0 0 4px;
    // }
}

.cardFooterItem {
    &:hover {
        background-color: hsl(0, 0%, 71%);
    }
}

.cardWarning {
    position: absolute;
    right: 4px;
    top: 4px;
}

.lightIcon {
    opacity: .64;
}

/********************************************/

.someSelectorArea {
    min-height: 480px;
}

.widgetHeader {
    line-height: initial;
    cursor: move;
}

.react-grid-item {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px;
    padding: 0 0 12px;
}

.dashboardLayout {
    width: 100vw;
}

/********************************************************/

@import '~loaders.css/src/animations/line-scale-pulse-out.scss';
 
.loader {
    animation: unset !important;
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
  width: 96px;

  div:not(.loader-inner) {
    width: 10px;
    height: 120px;
  }
}

.line-scale-pulse-out > div {
    background-color: $primaryColor;
}

.fullSizeDiv {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #dbdbdb;
    opacity: .72;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}


/******************** Color Picker Style *************/

input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 32px;
	height: 32px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

/******************* Raw Data Editor *********************/

#rawData {
    height: 100% !important;
    width: 100% !important;
    border: 1px solid #dbdbdb;
}

/*******************Accordion Collapser*****************************************/

.activeSection {
    transition: height 0.2s ease;
    height: auto;
}

// .collapsedSection {
//     height: 0px;
//     overflow-y: hidden !important;
// }

.message-body {
    &.is-collapsible {
        overflow-y: unset;
    }

    &.collapsedSection {
        height: 0px;
        overflow-y: hidden !important;
    }
}

/*-------------------------------------------------------------------*/

.airtable-embed {
    .message + div.m3 {
        display: none;
    }
}


/******************************************************************/

.popover {
    position: absolute;
    z-index: 2;
    right: 0px;
    top: 64px;
}

.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #eee;
    opacity: .4;
}

.loadDetailsBox {
    width: 480px;
    min-height: 180px;
    position: relative;
    background-color: #fff;
}