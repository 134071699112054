.chartGrid line {
    stroke: #EBECED;
    stroke-opacity: 0.72;
    shape-rendering: crispEdges;
}
  
.chartGrid path {
    stroke-width: 0;
}

.axis--x {
  &.removeDomain {
    .domain {
      // stroke: lightgray;
      visibility: hidden;
    }
  }
} 
  
.axis--x .tick line,
.axis--y .tick line,
.axis--y .domain {
    visibility: hidden;
}
  
.axis--x .tick text,
.axis--y .tick text {
    color: #444;
}
   
.axis--x {
    .tick:last-child {
      text {
        text-anchor: end;
      }
    }
  
    &.no-visibility {
      .domain {
        visibility: hidden;
      }
    }
}

.axis-grid line {
  stroke: #ddd;
  stroke-dasharray: 3 3;
}
  
.line {
    stroke-width: 1px;
}