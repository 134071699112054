// $orgNameColor : #754872;
$orgNameColor: #ca8ec6;
$footerBackground: #445463;
$footerBottomBackground: #313c46;
$itemColor: #eee;

.footer-logo {
    float: left;
    width: 48px;
}

.orgName {
    color: $orgNameColor;
}

.footer-size-7 {
    font-size: 13px;
    line-height: 17px;
}

.footer-top {
    background-color: $footerBackground;

    .footer_item {
        color: $itemColor;
    }

    .footer-title {
        h6 {
            color: $itemColor;
            font-weight: bold;
            font-size: 1.3rem;
        }
    }

    .footer-link {
        .footer-link-item {
            display: block;
            padding: 4px 0px;
            margin-left: 2px;
            color: $itemColor;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
                color: #aaa;
            }
        }
    }
}


.footer-bottom {
    background-color: $footerBottomBackground;
    color: $itemColor;

    .footer-link {
        .footer-link-item {
            margin-left: 2px;
            color: $itemColor;
            font-size: 13px;

            &:hover {
                text-decoration: underline;
                color: #aaa;
            }
        }
    }
}