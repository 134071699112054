.logo {
    display: flex;
    align-items: center;
  
    img {
      width: 40px;
    }
  
    h1 {
      font-family: "Rajdhani", sans-serif;
      font-size: 21px;
      line-height: 23px;
  
      margin-bottom: 0px;
      margin-left: 8px;
  
      word-wrap: break-word;
      width: 160px;
  
      // span {
      //   display: block;
      // }
    }
}