@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 16s linear;
  }
}


@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}