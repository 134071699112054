$backgroundColor: #FFF;
$logoBackground: #dcdef5;

$primaryColor: #669DA3;

$selectorBackground: #E2EEF4;

$chartHover: rgba(209,180,17,0.51);

$imageBackground: #0086ff;
